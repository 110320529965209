import {
  FETCH_BLIND,
  FETCH_BLINDS,
  SIGN_IN,
  SIGN_OUT,
  HANDLE_ANGLE_VALUE,
  HANDLE_POSITION_VALUE,
  HANDLE_SPEED_VALUE,
  SETTINGS_MODAL_VISIBLE,
  CHANGE_BLIND_INDEX,
} from 'context/blind/keys';

export const initialState = {
  blinds: [],
  blindIndex: 0,
  signed: false,
  settingsModalVisible: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETTINGS_MODAL_VISIBLE:
      return {
        ...state,
        settingsModalVisible: payload,
      };
    case CHANGE_BLIND_INDEX:
      return {
        ...state,
        blindIndex: payload,
      };
    case FETCH_BLINDS:
      return {
        ...state,
        blinds: payload,
      };
    case HANDLE_SPEED_VALUE:
      console.log('state form reduceer: ', state);
      return {
        ...state,
        blinds: state.blinds.map((el) =>
          el.BlindID === payload.BlindID ? { ...el, SpeedEngine: payload.SpeedEngine } : el
        ),
      };
    case HANDLE_ANGLE_VALUE:
      console.log('state form reduceer: ', state);
      return {
        ...state,
        blinds: state.blinds.map((el) =>
          el.BlindID === payload.BlindID ? { ...el, Angle: payload.Angle } : el
        ),
      };
    case HANDLE_POSITION_VALUE:
      console.log('state form reduceer: ', state);
      return {
        ...state,
        blinds: state.blinds.map((el) =>
          el.BlindID === payload.BlindID ? { ...el, Position: payload.Position } : el
        ),
      };
    case SIGN_IN:
      return { ...state, signed: true };
    case SIGN_OUT:
      return { ...state, signed: false };
    default:
      return state;
  }
};

export default reducer;
