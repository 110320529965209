import React, { useState, useContext, useEffect } from 'react';
import { RightOutlined } from '@ant-design/icons';

// components
import CircleSlider from 'ui/components/circle-slider';

// styles
import styles from './home.module.scss';
import { Modal, Input, Button } from 'antd';
import { BlindContext } from 'context';
import history from 'history.js';

let timeoutId;

export default function Home() {
  const [angle, setAngle] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [blindType, setBlindType] = useState('');
  const [blindName, setBlindName] = useState('');

  const {
    getBlinds,
    setBlind,
    setSettingsModalVisible,
    setAutoTurn,
    setSettings,
    state: { blinds, settingsModalVisible, blindIndex },
  } = useContext(BlindContext);

  useEffect(() => {
    console.log('blinds', blinds, blinds?.[blindIndex]?.Angle);
    if (blinds[blindIndex]) {
      setAngle(blinds[blindIndex].Angle);
      setSpeed(blinds[blindIndex].SpeedEngine);
      setBlindName(blinds[blindIndex].Name);
      setBlindType(blinds[blindIndex].BlindType);
    }
  }, [blinds, blindIndex]);

  const blind = blinds?.[blindIndex];

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (localStorage.getItem('authenticated') && user) {
        getBlinds(user.username, user.password);
      } else history.push('/signin');
    } catch (err) {
      history.push('/signin');
    }
  }, []);

  const sendChance = (angle, speed) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(async () => {
      await setBlind(blind.BlindID, blind.BlindType, angle, speed);
      getBlinds();
    }, 1000);
  };

  const onCircleSliderChange = (value) => {
    if (value === angle) return;
    console.log('value', value);
    setAngle(value);
    sendChance(value, speed);
  };

  const onCircleSlider2Change = (value) => {
    if (value === speed) return;
    console.log('value', value);
    setSpeed(value);
    sendChance(angle, value);
  };

  return (
    <div className={styles.home_page_container}>
      {blindType != '2' && (
        <div className={styles.section_container}>
          <div className={styles.section_label_container}>
            <div className={styles.section_label_text}>AÇI</div>
            <RightOutlined style={{ color: '#e31e24', fontSize: '24px' }} />
          </div>
          <div className={styles.section_indicator_container}>
            <CircleSlider
              onChange={onCircleSliderChange}
              min={0}
              max={180}
              appendToValue='°'
              label='_'
              dataIndex={angle || 0}
            />
          </div>
          <div className={styles.section_value_container}>{angle + '°'}</div>
        </div>
      )}

      {blindType != '2' && (
        <div className={styles.section_container}>
          <div className={styles.section_label_container}>
            <div className={styles.section_label_text}>HIZ</div>
            <RightOutlined style={{ color: '#e31e24', fontSize: '24px' }} />
          </div>
          <div className={styles.section_indicator_container}>
            <CircleSlider
              onChange={onCircleSlider2Change}
              min={0}
              max={5}
              appendToValue=''
              label='_'
              dataIndex={speed || 0}
            />
          </div>
          <div className={styles.section_value_container}>{speed}</div>
        </div>
      )}

      {blindType == '2' && (
        <div style={{ alignSelf: 'center' }}>
          <div className={styles.section_indicator_container} style={{ margin: '10px' }}>
            <Button
              style={{ width: '150px' }}
              onClick={() => {
                setAngle(1);
                sendChance(1, speed);
              }}
            >
              Aç
            </Button>
          </div>
          <div className={styles.section_indicator_container} style={{ margin: '10px' }}>
            <Button
              style={{ width: '150px' }}
              onClick={() => {
                setAngle(170);
                sendChance(170, speed);
              }}
            >
              Kapat
            </Button>
          </div>
        </div>
      )}

      <div className={styles.footer_container}>
        <div className={styles.footer_section_container}>
          <div className={styles.footer_section_label}>Otomatik Dönüş</div>

          <div className={styles.radio_btn_container}>
            <label className={styles.switch} htmlFor='checkbox'>
              <input
                type='checkbox'
                id='checkbox'
                checked={blind?.AutoTurn}
                onChange={async () => {
                  console.log('autoturn change');
                  await setAutoTurn(blind?.BlindID, !blind?.AutoTurn);
                  getBlinds();
                }}
              />
              <div className={`${styles.slider} ${styles.round}`}></div>
            </label>
          </div>
        </div>

        <div className={styles.footer_section_container}>
          <div className={styles.footer_section_label}>Güneş Yokken Açık</div>

          <div className={styles.radio_btn_container}>
            <label className={styles.switch} htmlFor='checkbox2'>
              <input
                type='checkbox'
                id='checkbox2'
                checked={blind?.OpenAfterSun}
                onChange={async () => {
                  await setSettings(blind?.BlindID, blind?.Name, !blind?.OpenAfterSun);
                  getBlinds();
                }}
              />
              <div className={`${styles.slider} ${styles.round}`}></div>
            </label>
          </div>
        </div>
      </div>
      <Modal
        visible={settingsModalVisible}
        title='Panjur Ayarları'
        onOk={async () => {
          await setSettings(blind?.BlindID, blindName, blind?.OpenAfterSun);
          setSettingsModalVisible(false);
          getBlinds();
        }}
        onCancel={() => {
          setBlindName(blind?.Name);
          setSettingsModalVisible(false);
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setBlindName(blind?.Name);
              setSettingsModalVisible(false);
            }}
          >
            İptal
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={async () => {
              await setSettings(blind?.BlindID, blindName, blind?.OpenAfterSun);
              setSettingsModalVisible(false);
              getBlinds();
            }}
          >
            Onayla
          </Button>,
        ]}
      >
        <p>Panjur Adı:</p>
        <Input
          placeholder='örn: Toplantı Salonu'
          value={blindName}
          onChange={({ target }) => {
            setBlindName(target.value);
          }}
        />
      </Modal>
    </div>
  );
}
