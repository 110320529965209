// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".landing-page_landing_page_container__3Ek3W {\n  height: 100vh;\n  width: 100%;\n  background-size: cover; }\n", "",{"version":3,"sources":["webpack://src/ui/pages/landing-page/landing-page.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB,EAAA","sourcesContent":[".landing_page_container {\n  height: 100vh;\n  width: 100%;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing_page_container": "landing-page_landing_page_container__3Ek3W"
};
export default ___CSS_LOADER_EXPORT___;
