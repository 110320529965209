import React, { useState, useContext, useEffect } from 'react';
import { Select, Drawer } from 'antd';
import { SettingOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';

// styles
import './topbar.scss';
import { BlindContext } from 'context';
import history from 'history.js';

const { Option } = Select;

const Topbar = (props) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [userName, setUserName] = useState('');

  const {
    setSettingsModalVisible,
    changeBlindIndex,
    state: { blinds, settingsModalVisible, blindIndex },
  } = useContext(BlindContext);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setUserName(JSON.parse(localStorage.getItem('user')).username);
    }
  }, []);

  const drawerTitle = () => {
    return (
      <div className={'drawer_custom_title_container'}>
        <div className={'drawer_title_icon_container'}>
          <UserOutlined style={{ fontSize: '35px', color: 'white', fontWeight: 900 }} />
        </div>
        <div className={'drawer_title'}>{userName}</div>
      </div>
    );
  };

  return (
    <>
      <div className={'topbar_container'}>
        <div className={'hamburger_container'}>
          <div onClick={() => setVisibleDrawer(true)} className={'hamburger_btn'}>
            <div />
            <div />
            <div />
          </div>
        </div>
        <div className={'select_box_container'}>
          <Select
            defaultValue='lucy'
            style={{ width: 120 }}
            value={blindIndex}
            onChange={changeBlindIndex}
          >
            {blinds.map((el, i) => (
              <Option value={i}>{el.Name}</Option>
            ))}
          </Select>
        </div>
        <div className={'settings_btn_container'}>
          <SettingOutlined
            style={{ fontSize: '24px', color: 'dimgray' }}
            onClick={() => setSettingsModalVisible(true)}
          />
        </div>
      </div>
      <Drawer
        className={'sidebar_drawer_container'}
        title={drawerTitle()}
        placement={'left'}
        closable={false}
        onClose={() => setVisibleDrawer(false)}
        visible={visibleDrawer}
        key={'drawervicisble'}
      >
        <div className={'drawer_menu_container'}>
          <div>
            <p
              onClick={() => {
                history.push('/home');
                setVisibleDrawer(false);
              }}
              className={'menu_item'}
            >
              Panjur Kontrol
            </p>
            <p
              onClick={() => {
                history.push('/home');
                setVisibleDrawer(false);
              }}
              className={'menu_item'}
            >
              Senaryo Yönetimi
            </p>
            <p
              onClick={() => {
                history.push('/home');
                setVisibleDrawer(false);
              }}
              className={'menu_item'}
            >
              Bildirimler
            </p>
          </div>
          <div
            onClick={() => {
              localStorage.removeItem('authorized');
              localStorage.removeItem('user');
              history.push('/');
            }}
            className={'drawer_footer_container'}
          >
            <div className={'log_out_text'}>Çıkış Yap</div>
            <LogoutOutlined className={'log_out_icon'} />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Topbar;
