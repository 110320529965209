// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inner-pages_main_content_container__tFxcA {\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n  overflow: auto; }\n", "",{"version":3,"sources":["webpack://src/ui/pages/inner-pages/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,cAAc,EAAA","sourcesContent":[".main_content_container {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_content_container": "inner-pages_main_content_container__tFxcA"
};
export default ___CSS_LOADER_EXPORT___;
