import React from 'react';
import { Route } from 'react-router-dom';

// inner-pages
import Home from 'ui/pages/inner-pages/home';

// components
import Topbar from 'ui/components/topbar';

// contexts
// import { LanguageContext, AuthContext } from 'context';

import styles from './index.module.scss';

const InnerPages = () => {
  return (
    <>
      <Topbar />
      <div className={styles.main_content_container}>
        <Route path='/home' exact component={Home} />
      </div>
    </>
  );
};

export default InnerPages;
