import { apiURL, axiosClient } from 'service';
import {
  CHANGE_BLIND_INDEX,
  FETCH_BLIND,
  FETCH_BLINDS,
  HANDLE_ANGLE_VALUE,
  HANDLE_POSITION_VALUE,
  HANDLE_SPEED_VALUE,
  SETTINGS_MODAL_VISIBLE,
  SIGN_IN,
} from 'context/blind/keys';

export const getBlinds = (dispatch, getState) => async () => {
  const user = JSON.parse(localStorage.getItem('user'));

  const response = await fetch('/blinds', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(user),
  }).then(function (response) {
    return response.json(); // .text();
  });

  // console.log('state from action: ', getState);
  // const state = getState();
  // console.log('state from action: ', state);
  // if (JSON.stringify(state.blinds) != JSON.stringify(response)) {
  dispatch({ type: FETCH_BLINDS, payload: response.blinds });
  // }
};

export const setBlind = (dispatch) => async (BlindID, BlindType, angle, speed) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const response = await fetch('/blindchange', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      username: user.username,
      password: user.password,
      BlindID,
      BlindType,
      Angle: angle,
      SpeedEngine: speed,
    }),
  });
};

export const setAutoTurn = (dispatch) => async (BlindID, autoTurn) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const response = await fetch('/autoturn', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      username: user.username,
      password: user.password,
      BlindID,
      autoTurn,
    }),
  });
};

export const setSettings = (dispatch) => async (BlindID, blindName, openAfterSun) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const response = await fetch('/blindsettings', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      username: user.username,
      password: user.password,
      BlindID,
      blindName,
      openAfterSun,
    }),
  });
};

export const signIn = (dispatch) => async (values, callback) => {
  console.log('values', values);
  const resp = await axiosClient.post('/login', values);
  console.log('resp', resp);
  dispatch({ type: SIGN_IN, payload: true });
  localStorage.setItem('user', JSON.stringify(values));
  localStorage.setItem('authenticated', true);
  callback();
};

export const signOut = (dispatch) => () => {
  localStorage.removeItem('authenticated');
  localStorage.removeItem('user');
  dispatch({
    type: 'SIGN_OUT',
    payload: false,
  });
};

export const setSpeedValue = (dispatch) => (value, id, callback) => {
  dispatch({
    type: HANDLE_SPEED_VALUE,
    payload: { SpeedEngine: value, BlindID: id },
  });
  callback();
};

export const setAngleValue = (dispatch) => (value, id, callback) => {
  dispatch({
    type: HANDLE_ANGLE_VALUE,
    payload: { Angle: value * 10, BlindID: id },
  });
  callback();
};

export const setPositionValue = (dispatch) => (value, id, callback) => {
  dispatch({
    type: HANDLE_POSITION_VALUE,
    payload: { Position: value, BlindID: id },
  });
  callback();
};
export const setSettingsModalVisible = (dispatch) => (isVisible) => {
  dispatch({
    type: SETTINGS_MODAL_VISIBLE,
    payload: isVisible,
  });
};
export const changeBlindIndex = (dispatch) => (index) => {
  dispatch({
    type: CHANGE_BLIND_INDEX,
    payload: index,
  });
};
