import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

// pages
import LandingPage from 'ui/pages/landing-page';
import SignInPage from 'ui/pages/authentication/signin-page';
import InnerPages from 'ui/pages/inner-pages';

// contexts
import history from './history';

// styles
import './app.css';

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path='/' exact component={LandingPage} />
          <Route path='/signin' exact component={SignInPage} />
          <Route component={InnerPages} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
