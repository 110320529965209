import React, { useEffect } from 'react';
//import { Link } from 'react-router-dom';

import history from 'history.js';

// styles
//import logo from 'resources/images/stoper-s-logo.png';
import styles from './landing-page.module.scss';

export default function LandingPage() {
  useEffect(() => {
    if (localStorage.getItem('authenticated')) {
      history.push('/home');
    } else history.push('/signin');
  }, []);
  return <div className={styles.landing_page_container}>redirecting..</div>;
}
