import React from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';

//import styles from './aa.module.scss';

export default function CircleSlider({ min, max, appendToValue, onChange, label, dataIndex }) {
  const data = [];
  for (let a = min; a < max + 1; a++) data.push(a);
  return (
    <CircularSlider
      onChange={onChange}
      // min={min}
      // max={max}
      dataIndex={dataIndex}
      data={data}
      appendToValue={appendToValue}
      width={140}
      direction={1}
      knobPosition='bottom'
      knobColor='white'
      knobSize={30}
      label={label}
      valueFontSize='24px'
      labelColor='black'
      progressSize={30}
      progressColorFrom='#e31e24'
      progressColorTo='#e31e24'
      trackColor='white'
      trackSize={30}
      onBlur={() => console.log('blur')}
    />
  );
}
