import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { BlindContext } from 'context';
import history from 'history.js';

// helpers
// import { isEmail, isEmpty, isValidPassword } from 'utils';
// import { useForm } from 'hooks';

// styles
import logo from 'resources/images/stoper-s-logo.png';
import styles from './signin-page.module.scss';

export default function SignInPage() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const {
    state: { blinds },
    signIn,
  } = useContext(BlindContext);
  return (
    <form
      className={styles.signin_container}
      onSubmit={(e) => {
        e.preventDefault();
        signIn({ username: userName, password }, () => history.push('/home'));
      }}
    >
      <Link to='/'>
        <img className={styles.logo} src={logo} alt='logo' />
      </Link>

      <div className={styles.inputs_area}>
        <div className={styles.form_input_container}>
          <input
            className={styles.form_input}
            placeholder='Kullanıcı Adı'
            type='text'
            name='login'
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className={styles.form_input_container}>
          <input
            className={styles.form_input}
            placeholder='Şifre'
            type='password'
            name='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>

      <div className={styles.footer_area}>
        <div className={styles.form_button_container}>
          <button className={styles.form_button}>Giriş Yap</button>
        </div>
      </div>
    </form>
  );
}
